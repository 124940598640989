.hero-section-strategi {
  background-image: url(../../assets/strategi-hero.jpg);
  background-size: 100% 100%;
  background-position: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.container-strategi {
  max-width: 1700px;
  margin: 0 auto;
  margin-top: 4rem;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4rem;
  /* background-color: #b50b0b; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.strategi {
  flex: 45%;
  background-color: #fff;
  padding: 2rem;
  margin-bottom: 2rem;
  height: 100%;
}

.strategi h2 {
  font-size: 2.4rem;
  margin-bottom: 1rem;
  color: #b50b0b;
  font-weight: 600;
}

.transformasi-budaya {
  background-image: url(../../assets/strategi-budaya.jpg);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 25rem;
  margin-bottom: 1rem;
  object-fit: cover;
  object-position: center;
}
.transformasi-bisnis {
  background-image: url(../../assets/strategi-bisnis.jpg);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 25rem;
  margin-bottom: 1rem;
  object-fit: cover;
  object-position: center;
}
.strategi li {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) and (max-width: 991px) {
  .hero-section-strategi {
    height: 25rem;
  }
  .container-strategi {
  }
  .strategi {
    padding: 0.5rem;
  }
  .strategi h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #b50b0b;
    font-weight: 600;
  }
  .strategi li {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .hero-section-strategi {
    height: 25rem;
  }
  .container-strategi {
    flex-direction: column;
  }
  .strategi {
    padding: 0.5rem;
    /* background-color: #b50b0b; */
  }
  .strategi h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #b50b0b;
    font-weight: 600;
  }
  .strategi li {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}
@media (min-width: 360px) and (max-width: 479px) {
  .hero-section-strategi {
    height: 22rem;
  }
  .container-strategi {
    flex-direction: column;
  }
  .strategi {
    padding: 0.5rem;
  }
  .strategi h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #b50b0b;
    font-weight: 600;
  }
  .strategi li {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}
