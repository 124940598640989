.hero-section-kemitraan {
  background-image: url("https://images.unsplash.com/photo-1583321500900-82807e458f3c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80");
  background-size: 100% 100%;
  background-position: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}
@media (min-width: 480px) and (max-width: 767px) {
  .hero-section-kemitraan {
    height: 23rem;
  }
}
@media (min-width: 360px) and (max-width: 479px) {
  .hero-section-kemitraan {
    height: 20rem;
  }
}
