/* 
-----------
hero kredit
-----------
*/

.hero-img-kreditKerja {
  background-image: url(/src/assets/kredit-modal-kerja-hero.jpg);
  background-size: 100% 100%;
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 80vh;
  margin-top: 3rem;
  display: flex;
  align-items: center;
}

.feature i {
  color: #f50f0f;
}
/* 
---------------------------
deskripsi kredi modal kerja
---------------------------
*/
.deskripsi-kedit-kerja {
  margin-inline: 20rem;
  margin-block: 6rem;
}

.deskripsi-kedit-kerja h2 {
  font-size: 2.4rem;
  text-align: center;
  color: #b50b0b;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.deskripsi-kedit-kerja p {
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  /* padding-inline: 20rem; */
}

/* 
--------------------------------
fitur manfaat dan syarat kredit
--------------------------------
*/

/* Keunggulan produk ada di simkonvensional.css */
.fitur-manfaat-prasayart-kredit {
  background-image: url(/src/assets/kredit-kerja.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  padding-inline: 8rem;
  padding-block: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
}
.cont-fitur-manfaat-kredit {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex-basis: 50%;
  align-items: flex-end;
  /* background-color: #f50f0f; */
}
.site-prasarat-kredit {
  background-color: rgba(255, 255, 255);
  padding-inline: 3rem;
  border-radius: 1rem;
  padding-block: 3rem;
  width: 45rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.site-prasarat-kredit h3 {
  margin: 0;
  /* color: #b50b0b; */
  font-size: 2rem;
  font-weight: 600;
}

.site-prasarat-kredit ol {
  text-align: start;
}

.site-prasarat-kredit li {
  margin-bottom: 0.3rem;
  font-size: 1.1rem;
  text-align: start;
}

/* 
---------
responsif
--------- 
*/

@media (min-width: 1200px) and (max-width: 1399px) {
  .hero-img-kreditKerja {
    margin-top: 3rem;
    height: 32rem;
  }

  /* 
  ---------------------------
  fitur prasyarat dan manfaat
  ---------------------------
  */
  .fitur-manfaat-prasayart-kredit {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-inline: 1.5rem;
    padding-block: 5rem;
  }
  .cont-fitur-manfaat-kredit {
    width: 100%;
    align-items: center;
  }
  .site-fitur-manfaat-simpanan {
    width: 100%;
  }
  .site-fitur-manfaat-simpanan h3 {
    font-size: 1.8rem;
  }
  .site-prasarat-simpanan h5 {
    font-size: 1.4rem;
    font-weight: 400;
  }
  .site-fitur-manfaat-simpanan p {
    font-size: 1rem;
  }
  .cont-fitur-manfaat-kanan {
    width: 100%;
  }
  .site-prasarat-kredit {
    width: 100%;
    padding: 1rem;
  }
  .site-prasarat-kredit h3 {
    font-size: 1.8rem;
    font-weight: 600;
  }
  .site-prasarat-kredit li {
    font-size: 1rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hero-img-kreditKerja {
    margin-top: 3rem;
    height: 28rem;
  }
  /* 
  ------------------
  deskripsi kredit
  ------------------
  */
  .deskripsi-kedit-kerja {
    margin-inline: 1rem;
    margin-block: 3rem;
  }
  .deskripsi-kedit-kerja h2 {
    font-size: 2rem;
  }
  .deskripsi-kedit-kerja p {
    font-size: 1.1rem;
    font-weight: 600;
  }
  /* 
  ---------------------------
  fitur prasyarat dan manfaat
  ---------------------------
  */
  .fitur-manfaat-prasayart-kredit {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-inline: 1.5rem;
    padding-block: 5rem;
  }
  .cont-fitur-manfaat-kredit {
    width: 100%;
    align-items: center;
  }
  .site-fitur-manfaat-simpanan {
    width: 100%;
  }
  .site-fitur-manfaat-simpanan h3 {
    font-size: 1.5rem;
  }
  .site-prasarat-simpanan h5 {
    font-size: 1.1rem;
    font-weight: 400;
  }
  .site-fitur-manfaat-simpanan p {
    font-size: 1rem;
  }
  .cont-fitur-manfaat-kanan {
    width: 100%;
  }
  .site-prasarat-kredit {
    width: 100%;
    padding: 1rem;
  }
  .site-prasarat-kredit h3 {
    font-size: 1.1rem;
    font-weight: 600;
  }
  .site-prasarat-kredit li {
    font-size: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hero-img-kreditKerja {
    margin-top: 2.6rem;
    height: 23rem;
  }
  /* 
  ------------------
  deskripsi kredit
  ------------------
  */
  .deskripsi-kedit-kerja {
    margin-inline: 1rem;
    margin-block: 3rem;
  }
  .deskripsi-kedit-kerja h2 {
    font-size: 2rem;
  }
  .deskripsi-kedit-kerja p {
    font-size: 1.1rem;
    font-weight: 600;
  }
  /* 
  ---------------------------
  fitur prasyarat dan manfaat
  ---------------------------
  */
  .fitur-manfaat-prasayart-kredit {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-inline: 1.5rem;
    padding-block: 5rem;
  }
  .cont-fitur-manfaat-kredit {
    width: 100%;
    align-items: center;
  }
  .site-fitur-manfaat-simpanan {
    width: 100%;
  }
  .site-fitur-manfaat-simpanan h3 {
    font-size: 1.2rem;
  }
  .site-prasarat-simpanan h5 {
    font-size: 1rem;
    font-weight: 400;
  }
  .site-fitur-manfaat-simpanan p {
    font-size: 0.8rem;
  }
  .cont-fitur-manfaat-kanan {
    width: 100%;
  }
  .site-prasarat-kredit {
    width: 100%;
    padding: 1rem;
  }
  .site-prasarat-kredit h3 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .site-prasarat-kredit li {
    font-size: 0.8rem;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .hero-img-kreditKerja {
    margin-top: 2.3rem;
    height: 23rem;
  }
  /* 
  ------------------
  deskripsi kredit
  ------------------
  */
  .deskripsi-kedit-kerja {
    margin-inline: 1rem;
    margin-block: 3rem;
  }
  .deskripsi-kedit-kerja h2 {
    font-size: 2rem;
  }
  .deskripsi-kedit-kerja p {
    font-size: 1rem;
    font-weight: 600;
  }
  /* 
  ---------------------------
  fitur prasyarat dan manfaat
  ---------------------------
  */
  .fitur-manfaat-prasayart-kredit {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 2rem;
    padding-block: 3rem;
  }
  .cont-fitur-manfaat-kredit {
    width: 100%;
  }
  .site-fitur-manfaat-simpanan {
    width: 100%;
  }
  .site-fitur-manfaat-simpanan h3 {
    font-size: 1.5rem;
  }
  .site-prasarat-simpanan h5 {
    font-size: 1.2rem;
    font-weight: 400;
  }
  .site-fitur-manfaat-simpanan p {
    font-size: 1rem;
  }
  .cont-fitur-manfaat-kanan {
    width: 100%;
  }
  .site-prasarat-kredit {
    width: 100%;
    padding: 1rem;
  }
  .site-prasarat-kredit h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .site-prasarat-kredit li {
    font-size: 1rem;
  }
}
@media (min-width: 360px) and (max-width: 479px) {
  .hero-img-kreditKerja {
    margin-top: 2.6rem;
    height: 20rem;
  }
  /* 
  ------------------
  deskripsi kredit
  ------------------
  */
  .deskripsi-kedit-kerja {
    margin-inline: 1rem;
    margin-block: 3rem;
  }
  .deskripsi-kedit-kerja h2 {
    font-size: 2rem;
  }
  .deskripsi-kedit-kerja p {
    font-size: 1.1rem;
    font-weight: 600;
  }
  /* 
  ---------------------------
  fitur prasyarat dan manfaat
  ---------------------------
  */
  .fitur-manfaat-prasayart-kredit {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 2rem;
    padding-block: 3rem;
  }
  .cont-fitur-manfaat-kredit {
    width: 100%;
  }
  .site-fitur-manfaat-simpanan {
    width: 100%;
  }
  .site-fitur-manfaat-simpanan h3 {
    font-size: 1.5rem;
  }
  .site-prasarat-simpanan h5 {
    font-size: 1.2rem;
    font-weight: 400;
  }
  .site-fitur-manfaat-simpanan p {
    font-size: 1rem;
  }
  .cont-fitur-manfaat-kanan {
    width: 100%;
  }
  .site-prasarat-kredit {
    width: 100%;
    padding: 1rem;
  }
  .site-prasarat-kredit h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .site-prasarat-kredit li {
    font-size: 1rem;
  }
}
