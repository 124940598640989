.formsdb {
  height: 80vh;
  background-color: aquamarine;
}

.hero-img-formsdb {
  background-image: url("../../assets/SDB-hero.jpg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 80vh;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-img-formsdb h1 {
  font-weight: 600;
  font-size: 3.5rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hero-img-formsdb {
    height: 30rem;
    margin-top: 2.6rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-img-formsdb {
    height: 25rem;
    margin-top: 2.6rem;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .hero-img-formsdb {
    height: 20rem;
    margin-top: 2.6rem;
  }
}
@media (min-width: 360px) and (max-width: 479px) {
  .hero-img-formsdb {
    height: 16rem;
    margin-top: 2.6rem;
  }
}
