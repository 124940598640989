.cont-menu-icon {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 3;
  position: fixed;
  right: 0;
  top: 40%;
}
.btn-menu1 {
  display: block;
  height: 60px;
  width: 60px;
  /* float: left; */
  margin: 0 5px;
  overflow: hidden;
  background: #b50b0b;
  /* border-radius: 50px; */
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
}
.btn-menu2 {
  display: block;
  height: 60px;
  width: 60px;
  /* float: left; */
  margin: 0 5px;
  overflow: hidden;
  background: #b50b0b;
  /* border-radius: 50px; */
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
}
.btn-menu3 {
  display: block;
  height: 60px;
  width: 60px;
  /* float: left; */
  margin: 0 5px;
  overflow: hidden;
  background: #b50b0b;
  /* border-radius: 50px; */
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
}
.btn-menu1:hover {
  width: 21rem;
}

.btn-menu2:hover {
  width: 14rem;
}
.btn-menu3:hover {
  width: 16rem;
}
.icon-menu {
  display: inline-block;
  height: 60px;
  width: 60px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 60px;
  transition: all 0.3 ease-out;
}
.list-icon-sidebar {
  transition: all 0.3s ease-out;
  color: white;
  width: 2.2rem;
}
.btn-menu1 a {
  text-decoration: none;
  margin-left: 1rem;
  color: white;
}

.btn-menu2 a {
  text-decoration: none;
  margin-left: 1rem;
  color: white;
}

.btn-menu3 a {
  text-decoration: none;
  margin-left: 1rem;
  color: white;
}
