/* 
-------------
hero deposito
-------------
*/

.hero-img-tabungan {
  /* background-image: url("https://i.ibb.co/YW9rQrD/front-view-plants-with-coins-stacked-dirt-banknote.jpg"); */
  background-image: url(../../assets/deposito-hero1.jpg);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 80vh;
  margin-top: 3rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.table-deposito {
  font-size: 1rem;
  margin: 5rem 5rem 0 0;
}
.table-deposito tbody,
.table-deposito thead {
  background: rgba(255, 255, 255, 0.5);
  border: 5px solid #b50b0b;
}

/* 
--------------------------
fitur dan manfaat deposito
--------------------------
*/
.fitur-manfaat-prasayart-deposito {
  background-image: url(../../assets/deposito1.avif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  padding-inline: 8rem;
  padding-block: 10rem;
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.cont-fitur-manfaat-kanan .site-prasarat-simpanan .accordion-button {
  font-size: 1.4rem;
}

/* Accordion form */
/* .site-fitur-manfaat-simpanan .accordion {
  width: 50%;
} */

@media (max-width: 1200px) and (max-width: 1399px) {
  /* 
  -----------------
  hero sections
  -----------------
  */
  .hero-img-tabungan {
    margin-top: 3rem;
    height: 32rem;
  }
  .table-deposito {
    font-size: 0.8rem;
    margin: 5rem 7rem 0 0;
  }
  /* 
  ---------------------------
  fitur prasyarat dan manfaat
  ---------------------------
  */
  .fitur-manfaat-prasayart-deposito {
    flex-direction: row;
    padding-inline: 1.5rem;
    padding-block: 5rem;
  }
  .site-prasarat-simpanan p {
    font-size: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  /* 
  -----------------
  hero sections
  -----------------
  */
  .hero-img-tabungan {
    margin-top: 2.6rem;
    height: 23rem;
  }
  .table-deposito {
    font-size: 0.8rem;
    margin: 5rem 7rem 0 0;
  }
  /* 
  ---------------------------
  fitur prasyarat dan manfaat
  ---------------------------
  */
  .fitur-manfaat-prasayart-deposito {
    flex-direction: row;
    padding-inline: 1rem;
    padding-block: 3rem;
  }
  .site-prasarat-simpanan p {
    font-size: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* 
  -----------------
  hero sections
  -----------------
  */
  .hero-img-tabungan {
    margin-top: 2.6rem;
    height: 23rem;
  }
  .table-deposito {
    font-size: 0.8rem;
    margin: 5rem 7rem 0 0;
  }
  /* 
  ---------------------------
  fitur prasyarat dan manfaat
  ---------------------------
  */
  .fitur-manfaat-prasayart-deposito {
    flex-direction: row;
    padding-inline: 1rem;
    padding-block: 3rem;
  }
  .site-prasarat-simpanan p {
    font-size: 1rem;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  /* 
  -----------------
  hero sections
  -----------------
  */
  .hero-img-tabungan {
    margin-top: 2.3rem;
    height: 23rem;
  }
  .table-deposito {
    font-size: 0.8rem;
    margin: 5rem 7rem 0 0;
  }
  /* 
  ---------------------------
  fitur prasyarat dan manfaat
  ---------------------------
  */
  .fitur-manfaat-prasayart-deposito {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 3rem;
    padding-block: 3rem;
  }
}
@media (min-width: 360px) and (max-width: 479px) {
  /* 
  -----------------
  hero sections
  -----------------
  */
  .hero-img-tabungan {
    margin-top: 2.6rem;
    height: 20rem;
  }
  .table-deposito {
    font-size: 0.7rem;
    margin: 5rem 5rem 0 0;
  }
  /* 
  ---------------------------
  fitur prasyarat dan manfaat
  ---------------------------
  */
  .fitur-manfaat-prasayart-deposito {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 2rem;
    padding-block: 2rem;
  }
}
