.cont_main_LPS {
  padding-block: 4rem;
}
.LPS_jaminan {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-inline: 5rem;
}
.img_lps_page {
  flex-basis: 50%;
}
.img_lps_page img {
  width: 50rem;
}
.des_LPS_page {
  flex-basis: 50%;
}
.des_LPS_page h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #b50b0b;
  margin-bottom: 0.6rem;
}
.des_LPS_page p {
  font-size: 1.2rem;
}
.cont_3T {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
}
.heading_3T {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}
.heading_3T img {
  width: 9rem;
}
.heading_3T h3 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #b50b0b;
}
.list_3T li strong {
  font-size: 1.5rem;
  font-weight: 600;
  color: #b50b0b;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .cont_main_LPS {
    padding-top: 0rem;
    padding-bottom: 3rem;
  }
  .LPS_jaminan {
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-inline: 1rem;
  }
  .img_lps_page img {
    width: 35rem;
  }
  /* .des_LPS_page p {
    font-size: 1rem;
  } */
}
@media (min-width: 992px) and (max-width: 1199px) {
  .cont_main_LPS {
    padding-top: 0rem;
    padding-bottom: 3rem;
  }
  .LPS_jaminan {
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-inline: 1rem;
  }
  .img_lps_page img {
    width: 30rem;
  }
  .des_LPS_page p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cont_main_LPS {
    padding-top: 0rem;
    padding-bottom: 3rem;
  }
  .LPS_jaminan {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-inline: 1rem;
  }
  .img_lps_page img {
    width: 30rem;
  }
  .des_LPS_page p {
    font-size: 1rem;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .cont_main_LPS {
    padding-top: 0rem;
    padding-bottom: 3rem;
  }
  .LPS_jaminan {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-inline: 1rem;
  }
  .img_lps_page img {
    width: 25rem;
  }
  .des_LPS_page p {
    font-size: 1rem;
  }
}
@media (min-width: 360px) and (max-width: 479px) {
  .cont_main_LPS {
    padding-top: 0rem;
    padding-bottom: 3rem;
  }
  .LPS_jaminan {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-inline: 1rem;
  }
  .img_lps_page img {
    width: 20rem;
  }
  .des_LPS_page p {
    font-size: 1rem;
  }
}
