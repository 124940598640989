/* 
-------------------
slider keunggulan 
-------------------
*/
.container-project {
  height: 15rem;
  background-color: #b50b0b;
  margin-block: 2rem;
  padding: 0.5rem 0.5rem;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.container-project img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-position: center; */
  transition: 0.5s ease-in-out;
  border-radius: 0.5rem;
}

.container-project .disc {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -115%;
  text-align: left;
  padding: 0.5rem;
  height: fit-content;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));
  transition: ease-in-out 0.6s;
  padding: 1rem;
}

.container-project h1 {
  font-size: 1rem;
  color: white;
  text-align: center;
}

.container-project p {
  width: 80%;
  font-size: 0.7rem;
  color: white;
  margin-block: 0.5rem;
}

.container-project button {
  align-items: center;
  margin-top: 1rem;
  color: red;
}

.container-project:hover img {
  transform: scale(1.3);
}

.container-project:hover .disc {
  bottom: 0;
}
/* 
--------------------------
main container keunggulan
--------------------------
*/
.container-projects {
  width: 80%;
  max-width: 1280px;
  margin-inline: auto;
  /* padding: 3rem 0; */
  text-align: center;
}

.container-projects .title {
  font-size: 2.2rem;
  text-align: center;
  color: #b50b0b;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.container-projects .sub-title {
  width: 50%;
  margin: 0 auto;
  padding: 1rem 0;
  font-size: 1.1rem;
}

.container-projects .slick-list {
  /* background-color: #250d0d; */
  height: 24rem;
  align-items: center;
}

.cont-cart-keunggulan {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  /* background-color: rgb(40, 59, 59); */
}
.cont-cart-keunggulan h2 {
  margin-bottom: 1.5rem;
  font-size: 2.4rem;
  font-weight: 600;
  color: #b50b0b;
}

.cont-showCart {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: center;
  margin-inline: 10rem;
}

/* 
--------------------
cart keunggulan new
--------------------
*/
.new-container-keunggulan {
  max-width: 25rem;

  /* height: 40rem; */
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  /* margin-top: 3rem; */
  overflow: hidden;
}
.new-container-keunggulan img {
  width: 100%;
  min-height: 20rem;
}
.new-desc-keunggulan {
  margin: 1rem;
  /* --max-line: 5;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-line); */
}
.new-desc-keunggulan h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
  color: black;
}
.hide-text {
  --max-line: 5;
  --line-height: 1.5;
  max-height: calc(var(--max-line) * 1em * var(--line-height));
  line-height: var(--line-height);
  font-size: 1rem;
  /* font-weight: 600; */
  overflow: hidden;
  position: relative;
}
.hide-text:has(+ .expand-btn:not(:checked)):before {
  --trans-height: 5;
  content: "";
  position: absolute;
  height: calc(1em * var(--trans-height));
  bottom: 0;
  pointer-events: none;
  background: linear-gradient();
}
.expand-btn {
  appearance: none;
  border: 1px solid #b50b0b;
  padding: 0.4rem;
  font-weight: 400;
  border-radius: 0.2rem;
  cursor: pointer;
  margin-top: 1rem;
}
.expand-btn:hover {
  background-color: #b50b0b;
  color: white;
}
.expand-btn::before {
  content: "Lihat lebih banyak";
}
.expand-btn:checked:before {
  content: "Sembunyikan";
}
.hide-text:has(+ .expand-btn:checked) {
  max-height: none;
}
/* 
  --------------------------------------
  empat keunggulan baru bagian testimoni
  --------------------------------------
  */
.new-container-testimoni {
  max-width: 30rem;
  /* height: 40rem; */
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  /* margin-top: 3rem; */
  padding: 1rem;
}
.testimoni-nasabah {
  display: flex;
  gap: 1rem;
  /* background-color: aquamarine; */
  align-items: center;
  margin-bottom: 1rem;
}
.testimoni-nasabah img {
  width: 9rem;
  height: 100%;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}
.testimoni-nasabah h2 {
  font-size: 1.5rem;
  margin-bottom: 0.2rem;
}
.testimoni-nasabah p {
  font-size: 1rem;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .cont-showCart {
    /* background-color: #b50b0b; */
    margin-inline: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .cont-showCart {
    /* background-color: #b50b0b; */
    margin-inline: 0.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cont-showCart {
    /* justify-content: center; */
    margin-inline: 0rem;
  }
  /* 
  --------------------------------------
  empat keunggulan baru bagian testimoni
  --------------------------------------
  */
  .new-container-testimoni {
    max-width: 21rem;
  }
  .new-container-keunggulan,
  .new-container-testimoni {
    margin-inline: 0rem;
  }
  .testimoni-nasabah img {
    width: 5rem;
  }
  /* 
  --------------------------------------
  empat keunggulan baru bagian general
  --------------------------------------
  */
  .new-container-keunggulan {
    max-width: 21rem;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .cont-showCart {
    /* background-color: #b50b0b; */
    margin-right: 0rem;
    margin-left: 0rem;
    flex-direction: column;
    gap: 2rem;
  }
  /* 
  --------------------------------------
  empat keunggulan baru bagian testimoni
  --------------------------------------
  */
  .new-container-keunggulan,
  .new-container-testimoni {
    margin-inline: 1rem;
  }
  .testimoni-nasabah img {
    width: 7rem;
  }
}
@media (min-width: 360px) and (max-width: 479px) {
  .cont-showCart {
    flex-direction: column;
    gap: 2rem;
    margin-inline: 1rem;
  }
  /* 
  --------------------------------------
  empat keunggulan baru bagian testimoni
  --------------------------------------
  */
  .new-container-keunggulan,
  .new-container-testimoni {
    margin-inline: 0rem;
  }
  .testimoni-nasabah img {
    width: 7rem;
  }
}
