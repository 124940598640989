.hero-section-formSimpanan {
  background-image: url("https://images.unsplash.com/photo-1579621970588-a35d0e7ab9b6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80");
  background-size: 100% 100%;
  background-position: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}
.cont-form-simpanan {
  margin-block: 4rem;
  margin-inline: 10rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  padding-inline: 5rem;
  padding-block: 3rem;
}
.cont-formss h3 {
  font-size: 1.8;
  font-weight: 600;
  color: #b50b0b;
}
.form-pertama {
  display: flex;
  gap: 3rem;
  margin-block: 2rem;
  justify-content: space-between;
  /* background-color: #9f2626; */
}
.form-item1 .form-item2 {
  flex-basis: 50%;
}

.form-item1 {
  width: 100%;
}
.form-item2 {
  width: 100%;
}
.form-terakhir {
  display: flex;
  justify-content: space-between;
}
.BukaTabungan {
  font-size: 2rem;
}
.cont-btn-form {
  margin-block: 1.5rem;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cont-btn-form p {
  font-size: 1rem;
  text-align: center;
  margin-top: 1rem;
}
.TextErr {
  color: rgb(247, 2, 2);
}
@media (min-width: 769px) and (max-width: 1200px) {
  .cont-form-simpanan {
    margin-block: 1rem;
    margin-inline: 2rem;
    padding-inline: 2rem;
    padding-block: 1rem;
    /* font-size: 65%; */
  }
}
@media (max-width: 768px) {
  .form-pertama,
  .form-terakhir {
    flex-direction: column;
  }
  .form-pertama {
    gap: 0.1rem;
  }
  .cont-form-simpanan {
    margin-block: 1rem;
    margin-inline: 2rem;
    padding-inline: 2rem;
    padding-block: 1rem;
    /* font-size: 65%; */
  }

  .form-item1,
  .form-item2 {
    flex-basis: 100%;
  }
}
@media (min-width: 360px) and (max-width: 479px) {
  .hero-section-formSimpanan {
    height: 20rem;
    margin-top: 2.6rem;
  }
  .cont-formss h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .BukaTabungan {
    font-size: 1.4rem;
  }
}
