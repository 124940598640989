.hero_lelang {
  /* background-color: aqua; */
  background-image: url("../../assets/lelangHero2.jpg");
  background-position: center;
  background-size: cover;
  height: 40rem;
  margin-top: 4rem;
}
.container-lelang {
  margin-block: 3rem;
  margin-inline: 2rem;
}
.container-lelang h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.4rem;
  text-align: center;
  color: #b50b0b;
  font-weight: 600;
}
.card-container-lelang {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}
.lelang-card {
  height: 18rem;
}
.text_lelang {
  font-size: 1rem;
  /* font-weight: bold; */
  text-align: justify;
}

/* 
----------------------
CSS Lelang
----------------------
*/
@media (max-width: 1300px) {
  .hero_lelang {
    margin-top: 4.3rem;
  }
}
@media (max-width: 1200px) {
  .hero_lelang {
    margin-top: 2rem;
  }
}
@media (max-width: 992px) {
  .hero_lelang {
    margin-top: 2rem;
  }
}
@media (max-width: 768px) {
  .hero_lelang {
    margin-top: 2rem;
  }
}
@media (max-width: 480px) {
  .hero_lelang {
    margin-top: 2rem;
  }
  .container-lelang h2 {
    font-size: 2rem;
  }
}

/* 
----------------------
CSS Page Detail Lelang
----------------------
*/

.hero-detail-lelang {
  background-color: #b50b0b;
  background-image: url("../../assets/lelangHero1.jpg");
  background-position: center;
  background-size: cover;
  margin-top: 4rem;
  height: 35rem;
}

.container-detail-lelang {
  margin-inline: 15rem;
  margin-block: 3rem;
  /* height: 55vh; */
}
.des-img-lelang {
  display: flex;
  gap: 2rem;
  justify-content: center;
  /* background-color: aqua; */
}
.des-lelang {
  /* background-color: purple; */
  flex-basis: 50%;
}
.des-lelang ul li {
  font-size: 1rem;
}
.des-lelang h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
  color: #b50b0b;
}
.des-lelang h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.alamat-lelang p {
  margin-bottom: 0;
}

.img-lelang {
  /* background-color: aqua; */
  height: 100%;
  flex-basis: 50%;
}
.image-detail-lelang {
  height: 30rem;
  width: 20rem;
}
.info-lelang {
  margin-inline: 10rem;
  margin-top: 3rem;
  /* background-color: #b50b0b; */
}
.info-lelang .featuress {
  justify-content: center;
  gap: 2rem;
}
.a_lelang {
  text-decoration: none;
  color: black;
}
.descPicture-lelang {
  background-color: transparent;
}

@media (max-width: 1500px) {
  .hero-detail-lelang {
    margin-top: 2rem;
  }
  .container-detail-lelang {
    margin-inline: 5rem;
    gap: 1rem;
  }
  .info-lelang {
    margin-inline: 3rem;
  }
}

@media (max-width: 1400px) {
}

@media (max-width: 1300px) {
}

@media (max-width: 1200px) {
}
@media (max-width: 992px) {
  .hero-detail-lelang {
    margin-top: 2rem;
  }
  .container-detail-lelang {
    margin-inline: 3rem;
    gap: 1rem;
  }
  .descPicture-lelang {
    /* background-color: red; */
    width: 70%;
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 768px) {
  .hero-detail-lelang {
    margin-top: 2rem;
  }

  .container-detail-lelang {
    margin-inline: 3rem;
  }
  .des-img-lelang {
    flex-direction: column-reverse;
    gap: 1rem;
  }
  .descPicture-lelang {
    /* background-color: red; */
    width: 70%;
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 480px) {
  .hero-detail-lelang {
    margin-top: 2rem;
  }
  .container-detail-lelang {
    margin-inline: 2rem;
    margin-block: 3rem;
  }
  .des-img-lelang {
    flex-direction: column-reverse;
    gap: 1rem;
  }
  .des-lelang h2 {
    font-size: 2rem;
    margin-bottom: 0.1rem;
  }
  .des-lelang h3 {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .alamat-lelang h3 {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .alamat-lelang p {
    font-size: 1rem;
  }
  .info-lelang {
    margin-inline: 2rem;
  }
  .descPicture-lelang {
    /* background-color: red; */
    /* text-align: center; */
    width: 70%;
  }
}
