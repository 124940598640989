.hero-section-PPOB {
  background-image: url("../../assets/ppob-hero3.jpg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}
.cont-PPOB {
  display: flex;
  margin-inline: 6rem;
  margin-block: 6rem;
  align-items: center;
  justify-content: center;

  /* gap: 3rem; */
}
.cont-desc-PPOB {
  flex-basis: 50%;
  /* background-color: rebeccapurple; */
}
.cont-desc-PPOB h2 {
  font-size: 2.4rem;
  color: #b50b0b;
  margin-bottom: 1rem;
  font-weight: 600;
}
.cont-desc-PPOB p {
  font-size: 1.4rem;
}
.cont-img-PPOB {
  flex-basis: 50%;
  flex-direction: column;
  text-align: center;
}
.cont-img-PPOB img {
  width: 60vh;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .cont-PPOB {
    display: flex;
    margin-inline: 3rem;
    margin-block: 6rem;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hero-section-PPOB {
    height: 26rem;
    margin-top: 2.6rem;
  }
  .cont-PPOB {
    /* flex-direction: column; */
    align-items: start;
    margin-inline: 2rem;
    margin-block: 2rem;
    justify-content: center;
    gap: 1rem;
  }
  .cont-desc-PPOB h2 {
    font-size: 2rem;
  }
  .cont-desc-PPOB p {
    font-size: 1.1rem;
  }
  .cont-img-PPOB img {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-section-PPOB {
    height: 26rem;
    margin-top: 2.6rem;
  }
  .cont-PPOB {
    /* flex-direction: column; */
    align-items: start;
    margin-inline: 1.5rem;
    margin-block: 2rem;
    gap: 1rem;
  }
  .cont-desc-PPOB h2 {
    font-size: 2rem;
  }
  .cont-desc-PPOB p {
    font-size: 1.1rem;
  }
  .cont-img-PPOB img {
    width: 100%;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .hero-section-PPOB {
    height: 20rem;
    margin-top: 2.6rem;
  }
  .cont-PPOB {
    flex-direction: column;
    margin-inline: 2rem;
    margin-block: 2rem;
    gap: 1rem;
  }
  .cont-desc-PPOB h2 {
    font-size: 2rem;
  }
  .cont-desc-PPOB p {
    font-size: 1rem;
  }
  .cont-img-PPOB img {
    width: 100%;
  }
}
@media (min-width: 360px) and (max-width: 479px) {
  .hero-section-PPOB {
    height: 20rem;
    margin-top: 2.6rem;
  }
  .cont-PPOB {
    flex-direction: column;
    margin-inline: 2rem;
    margin-block: 2rem;
    gap: 1rem;
  }
  .cont-desc-PPOB h2 {
    font-size: 2rem;
  }
  .cont-desc-PPOB p {
    font-size: 1rem;
  }
  .cont-img-PPOB img {
    width: 100%;
  }
}
