.cont-hero-home {
  margin-top: 3rem;
  margin-bottom: 5rem;
  /* background-color: aqua; */
}

/* 
-----------
react Slick
-----------
*/

.slick-list,
.slick-slider,
.slick-track {
  padding: 1.5rem 0;
}

.center .slick-center .container-project {
  transform: scale(1.3);
  z-index: 10000;
  transition: all 0.6s;
  pointer-events: all;
  opacity: 1;
}

.center .container-project {
  opacity: 0.7;
  pointer-events: none;
}

@media (max-width: 768px) {
  .cont-hero-home {
    margin-top: 2rem;
  }
}
@media (max-width: 480px) {
  .cont-hero-home {
    margin-top: 2rem;
  }
}
@media (max-width: 360px) {
  .cont-hero-home {
    margin-top: 2rem;
  }
}
