/* 
---------------------------
hero simpanan konvensional
---------------------------
 */
.hero-img-simkon {
  background-image: url(../../assets/simpanan-konvensional-hero.jpg);
  background-size: 100% 100%;
  background-position: center;
  /* background-color: #b50b0b; */
  background-repeat: no-repeat;
  width: 100%;
  height: 80vh;
  margin-top: 3rem;
  display: flex;
  align-items: center;
}

.hero-section-simkon {
  height: auto;
  width: 35vw;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10%;
  background-color: rgba(0, 0, 0, 0.3);
}
.hero-section-simkon h1 {
  font-size: 3.5rem;
  font-weight: 600;
  color: #b50b0b;
  margin-bottom: 0.5rem;
}

.hero-section-simkon p {
  color: whitesmoke;
  font-size: 1.4rem;
}

/* 
--------------------------------
deskripsi simpanan konvensional
--------------------------------
*/
.deskripsi-simpanan-konvensional {
  margin-inline: 20rem;
  margin-block: 6rem;
}

.deskripsi-simpanan-konvensional h2 {
  font-size: 2.4rem;
  text-align: center;
  color: #b50b0b;
  margin-bottom: 0.5rem;
  font-weight: 600;
}
.deskripsi-simpanan-konvensional p {
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  /* padding-inline: 20rem; */
}
/* 
---------------------------------------
keunggulan produk simpanan dan features
---------------------------------------
*/
.keunggulan-produk {
  margin-bottom: 6rem;
}
.container-keunggulan {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: 13rem;
  /* background-color: #ffc107; */
}

.container-keunggulan h2 {
  font-size: 2.4rem;
  text-align: center;
  color: #b50b0b;
  font-weight: 600;
  margin-bottom: 3rem;
}
.container-cart-keunggulan {
  display: flex;
  justify-content: space-around;
  /* background-color: #b50b0b; */
}
.cart-keunggulan {
  flex-basis: calc(33.33% - 1rem);
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: white;
  border-radius: 0.5rem;
  text-align: center;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
}
.cart-keunggulan:hover {
  transform: translateY(-2px);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.5);
}
.cart-keunggulan .react-icon {
  font-size: 5rem;
  color: #b50b0b;
  margin-bottom: 1rem;
}
.cart-keunggulan h3 {
  color: #b50b0b;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.cart-keunggulan p {
  font-size: 1.1rem;
  font-weight: 600;
}

.cart-keunggulan i {
  font-size: 0.8rem;
}

/* .icon-simkon {
  color: #b50b0b;
} */

/* 
----------------------------------------
section fitur-manfaat-prasyrat simpanan
----------------------------------------
*/

.fitur-manfaat-prasayart-simpanan {
  background-image: url(../../assets/simpanan-konvensional.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  padding-inline: 8rem;
  padding-block: 10rem;
  /* background-color: #b50b0b; */
  display: flex;
  align-items: center;
  gap: 2.5rem;
}
.cont-fitur-manfaat-kiri {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex-basis: 50%;
  align-items: flex-end;
  /* background-color: #b50b0b; */
}
.site-fitur-manfaat-simpanan {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  width: 35rem;
  padding-inline: 2.5rem;
  padding-block: 2rem;
  gap: 1rem;
  color: white;
}
.site-fitur-manfaat-simpanan h3 {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0;
  color: white;
}
.site-fitur-manfaat-simpanan p {
  margin: 0;
  font-size: 1.1rem;
  color: white;
}

/*
---------------------------- 
css accordion download form 
----------------------------
*/

.form-pembukaan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 2rem;
}

.form-pembukaan h5 {
  color: black;
  font-weight: 600;
}

.fitur-manfaat-prasayart-simpanan .accordion-button {
  color: black;
}

.site-fitur-manfaat-simpanan .accordion {
  --bs-accordion-active-bg: #ffc107;
  --bs-accordion-border-color: none;
  --bs-accordion-bg: #ffc107;
  --bs-accordion-btn-bg: #ffc107;
}

.form-pembukaan button {
  padding: 1.2rem;
  border-radius: 10px;
  border: none;
  transition: all 0.3s;
}

.form-pembukaan button:hover {
  transform: translate(-2px, -2px);
  box-shadow: black 2px 2px;
  background-color: #ffc107;
  color: white;
  border: solid black 1px;
}

/* _____________________________________ */
.cont-fitur-manfaat-kanan {
  flex-basis: 50%;
  /* background-color: aqua; */
}

.cont-fitur-manfaat-kanan .accordion-button {
  font-size: 1.4rem;
  font-weight: 600;
}

.site-prasarat-simpanan {
  background-color: rgba(255, 255, 255);
  padding-inline: 3rem;
  border-radius: 1rem;
  padding-block: 3rem;
  width: 35rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.site-prasarat-simpanan h3 {
  margin: 0;
  /* color: #b50b0b; */
  font-size: 1.8rem;
  font-weight: 600;
}

.site-prasarat-simpanan p {
  font-size: 1.1rem;
  /* font-weight: 600; */
}

/* span untuk deposito */
.site-prasarat-simpanan span {
  color: blue;
}

.site-prasarat-simpanan ol {
  text-align: left;
}

.site-prasarat-simpanan li {
  margin-bottom: 0.3rem;
  font-size: 1.1rem;
}
.cont-fitur-manfaat-kanan .accordion .accordion-button {
  font-size: 1rem;
  font-weight: 600;
}
.cont-fitur-manfaat-kanan .site-prasarat-simpanan .accordion .accordion-button {
  font-size: 1rem;
  font-weight: 600;
  /* color: #b50b0b; */
}

/* 
----------------------------------------------
Adjust hero section height for smaller screens
----------------------------------------------
*/

.calkulator-simpanan {
  margin-inline: auto;
  margin-block: 3rem;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  /* 
  ---------------
  hero sections
  ---------------
  */
  .hero-img-simkon {
    margin-top: 3rem;
    height: 32rem;
  }
  /* 
  -------------------
  new cart Keunggulan
  -------------------
  */
  .container-keunggulan {
    margin-inline: 2.5rem;
  }
  .container-keunggulan h2 {
    font-size: 2.2rem;
  }
  .container-cart-keunggulan {
    justify-content: space-around;
  }
  .cart-keunggulan .react-icon {
    font-size: 4rem;
  }
  .cart-keunggulan h3 {
    font-size: 1.8rem;
    font-weight: 600;
  }
  .cart-keunggulan p {
    font-size: 1.1rem;
    font-weight: 600;
  }
  /* 
  ---------------------------
  fitur prasyarat dan manfaat
  ---------------------------
  */
  .fitur-manfaat-prasayart-simpanan {
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    padding-inline: 1.5rem;
    padding-block: 5rem;
  }
  .cont-fitur-manfaat-kiri {
    align-items: center;
  }
  .site-fitur-manfaat-simpanan {
    width: 100%;
  }
  .site-fitur-manfaat-simpanan Button {
    font-size: 1rem;
  }
  .site-fitur-manfaat-simpanan .Button-site-simpanan-kiri {
    font-size: 1rem;
  }
  .cont-fitur-manfaat-kanan {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .site-prasarat-simpanan {
    width: 100%;
    padding: 1.2rem;
  }
  .site-fitur-manfaat-simpanan h3 {
    font-size: 1.8rem;
    font-weight: 600;
  }
  .site-fitur-manfaat-simpanan p {
    font-size: 1rem;
  }
  .cont-fitur-manfaat-kanan h3 {
    font-size: 1.8rem;
    font-weight: 600;
  }
  .cont-fitur-manfaat-kanan li {
    font-size: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  /* 
  ---------------
  hero sections
  ---------------
  */
  .hero-img-simkon {
    margin-top: 3rem;
    height: 28rem;
  }
  /* 
  --------------------------------
  deskripsi simpanan konvensional
  --------------------------------
  */
  .deskripsi-simpanan-konvensional {
    margin-inline: 1rem;
    margin-block: 3rem;
  }
  .deskripsi-simpanan-konvensional h2 {
    font-size: 2rem;
  }
  .deskripsi-simpanan-konvensional p {
    font-size: 1.1rem;
    font-weight: 600;
  }
  /* 
  -------------------
  new cart Keunggulan
  -------------------
  */
  .container-keunggulan {
    margin-inline: 2rem;
  }
  .container-keunggulan h2 {
    font-size: 2rem;
  }
  .container-cart-keunggulan {
    justify-content: space-around;
  }
  .cart-keunggulan .react-icon {
    font-size: 4rem;
  }
  .cart-keunggulan h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .cart-keunggulan p {
    font-size: 1rem;
    font-weight: 600;
  }
  /* 
  ---------------------------
  fitur prasyarat dan manfaat
  ---------------------------
  */
  .fitur-manfaat-prasayart-simpanan {
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    padding-inline: 1.5rem;
    padding-block: 5rem;
  }
  .cont-fitur-manfaat-kiri {
    align-items: center;
  }
  .site-fitur-manfaat-simpanan {
    width: 100%;
  }
  .site-fitur-manfaat-simpanan Button {
    font-size: 1rem;
  }
  .site-fitur-manfaat-simpanan .Button-site-simpanan-kiri {
    font-size: 1rem;
  }
  .cont-fitur-manfaat-kanan {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .site-prasarat-simpanan {
    width: 100%;
    padding: 1.2rem;
  }
  .site-fitur-manfaat-simpanan h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .site-fitur-manfaat-simpanan p {
    font-size: 1rem;
  }
  .cont-fitur-manfaat-kanan h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .cont-fitur-manfaat-kanan li {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  /* 
  ---------------
  hero sections
  ---------------
  */
  .hero-img-simkon {
    margin-top: 2.6rem;
    height: 23rem;
  }
  /* 
  --------------------------------
  deskripsi simpanan konvensional
  --------------------------------
  */
  .deskripsi-simpanan-konvensional {
    /* background-color: #b50b0b; */
    margin-inline: 1rem;
    margin-block: 3rem;
  }
  .deskripsi-simpanan-konvensional h2 {
    font-size: 2rem;
  }
  .deskripsi-simpanan-konvensional p {
    font-size: 1.1rem;
    font-weight: 600;
  }
  /* 
  -------------------
  new cart Keunggulan
  -------------------
  */
  .container-keunggulan {
    margin-inline: 2rem;
  }
  .container-keunggulan h2 {
    font-size: 2rem;
  }
  .container-cart-keunggulan {
    gap: 1rem;
  }
  .cart-keunggulan .react-icon {
    font-size: 4rem;
  }
  .cart-keunggulan h3 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .cart-keunggulan p {
    font-size: 0.8rem;
    font-weight: 600;
  }
  /* 
  ---------------------------
  fitur prasyarat dan manfaat
  ---------------------------
  */
  .fitur-manfaat-prasayart-simpanan {
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    padding-inline: 1.5rem;
    padding-block: 5rem;
  }
  .cont-fitur-manfaat-kiri {
    align-items: center;
  }
  .site-fitur-manfaat-simpanan {
    width: 100%;
  }
  .site-fitur-manfaat-simpanan Button {
    font-size: 0.8rem;
  }
  .site-fitur-manfaat-simpanan .Button-site-simpanan-kiri {
    font-size: 0.8rem;
  }
  .cont-fitur-manfaat-kanan {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .site-prasarat-simpanan {
    width: 100%;
    padding: 1.2rem;
  }
  .site-fitur-manfaat-simpanan h3 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .site-fitur-manfaat-simpanan p {
    font-size: 0.8rem;
  }
  .cont-fitur-manfaat-kanan h3 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .cont-fitur-manfaat-kanan li {
    font-size: 0.8rem;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  /* 
  ---------------
  hero sections
  ---------------
  */
  .hero-img-simkon {
    margin-top: 2.3rem;
    height: 23rem;
  }
  /* 
  --------------------------------
  deskripsi simpanan konvensional
  --------------------------------
  */
  .deskripsi-simpanan-konvensional {
    /* background-color: #b50b0b; */
    margin-inline: 1rem;
    margin-block: 3rem;
  }
  .deskripsi-simpanan-konvensional h2 {
    font-size: 2rem;
  }
  .deskripsi-simpanan-konvensional p {
    font-size: 1rem;
    font-weight: 600;
  }
  /* 
  -------------------
  new cart Keunggulan
  -------------------
  */
  .container-keunggulan {
    margin-inline: 2rem;
  }

  .container-keunggulan h2 {
    font-size: 2rem;
  }
  .container-cart-keunggulan {
    flex-direction: column;
    gap: 1rem;
  }
  .cart-keunggulan h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .cart-keunggulan p {
    font-size: 1rem;
    font-weight: 600;
  }
  /* 
  ---------------------------
  fitur prasyarat dan manfaat
  ---------------------------
  */
  .fitur-manfaat-prasayart-simpanan {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 2rem;
    padding-block: 2rem;
  }
  .cont-fitur-manfaat-kiri {
    /* background-color: #b50b0b; */
    width: 100%;
  }
  .site-fitur-manfaat-simpanan {
    width: 100%;
    /* background-color: red; */
  }
  .site-fitur-manfaat-simpanan h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .site-fitur-manfaat-simpanan p {
    font-size: 1rem;
  }
  .cont-fitur-manfaat-kanan {
    width: 100%;
  }
  .site-prasarat-simpanan {
    width: 100%;
    padding: 1rem;
  }
  .site-prasarat-simpanan h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .site-prasarat-simpanan li {
    font-size: 1rem;
  }
  .cont-fitur-manfaat-kanan .accordion .accordion-button {
    font-size: 1rem;
    font-weight: 600;
  }
  .fitur-manfaat-prasayart-simpanan
    .cont-fitur-manfaat-kanan
    .accordion
    .accordion-button {
    font-size: 1rem;
    font-weight: 600;
  }
}
@media (min-width: 360px) and (max-width: 479px) {
  .hero-img-simkon {
    margin-top: 2.6rem;
    height: 20rem;
  }
  .container1 {
    margin-inline: 2%;
  }
  /* 
  --------------------------------
  deskripsi simpanan konvensional
  --------------------------------
  */
  .deskripsi-simpanan-konvensional {
    /* background-color: #b50b0b; */
    margin-inline: 1rem;
    margin-block: 3rem;
  }
  .deskripsi-simpanan-konvensional h2 {
    font-size: 2rem;
  }
  .deskripsi-simpanan-konvensional p {
    font-size: 1.1rem;
    font-weight: 600;
  }
  /* 
  ---------------------------------
  new cart keunggulan
  ---------------------------------
  */
  .container-keunggulan {
    margin-inline: 1.5rem;
  }
  .container-keunggulan h2 {
    font-size: 2rem;
  }
  .container-cart-keunggulan {
    flex-direction: column;
    gap: 0.5 rem;
  }
  .cart-keunggulan h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .cart-keunggulan p {
    font-size: 1rem;
    font-weight: 600;
  }
  /* 
  ---------------------------
  fitur prasyarat dan manfaat
  ---------------------------
  */
  .fitur-manfaat-prasayart-simpanan {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-inline: 2rem;
    padding-block: 2rem;
  }
  .cont-fitur-manfaat-kiri {
    /* background-color: #b50b0b; */
    width: 100%;
  }
  .site-fitur-manfaat-simpanan {
    width: 100%;
    /* background-color: red; */
  }
  .site-fitur-manfaat-simpanan h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .site-fitur-manfaat-simpanan p {
    font-size: 1rem;
  }
  .cont-fitur-manfaat-kanan {
    width: 100%;
  }
  .site-prasarat-simpanan {
    width: 100%;
    padding: 1rem;
  }
  .site-prasarat-simpanan h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .site-prasarat-simpanan li {
    font-size: 1rem;
  }
  .cont-fitur-manfaat-kanan .accordion .accordion-button {
    font-size: 1rem;
    font-weight: 600;
  }
  .fitur-manfaat-prasayart-simpanan
    .cont-fitur-manfaat-kanan
    .accordion
    .accordion-button {
    font-size: 1rem;
    font-weight: 600;
  }
}
