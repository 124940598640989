.hero-section-visi {
  background-image: url(../../assets/visi-misi-hero.jpg);
  background-size: 100% 100%;
  background-position: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

/* 
--------------------
css baru visi misi
--------------------
*/
.new-container-vismis {
  padding: 1.5rem;
  display: flex;
  margin-inline: 6rem;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.new-visi {
  flex-basis: 50%;
}
.new-visi h2 {
  font-size: 2.4rem;
  font-weight: 600;
  color: #b50b0b;
}
.new-visi p {
  font-size: 1.4rem;
}
.new-misi {
  flex-basis: 50%;
}
.new-misi h2 {
  font-size: 2.4rem;
  font-weight: 600;
  color: #b50b0b;
}
.new-misi p {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}
.new-misi li {
  font-size: 1.4rem;
  color: black;
  text-align: start;
  margin-bottom: 0.5rem;
}
.new-misi b {
  font-size: 1.5rem;
  color: #b50b0b;
}
/* .new-visi {
  background-color: aqua;
}
.new-misi {
  background-color: #b50b0b;
} */

@media (min-width: 1200px) and (max-width: 1399px) {
  .hero-section-visi {
    height: 25rem;
    margin-top: 2.6rem;
  }
  .new-container-vismis {
    margin-inline: 1.5rem;
    justify-content: center;
    gap: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hero-section-visi {
    height: 25rem;
    margin-top: 2.6rem;
  }
  .new-container-vismis {
    margin-inline: 1.5rem;
    /* flex-direction: column; */
    gap: 1rem;
    padding-inline: 1rem;
  }
  .new-visi h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #b50b0b;
  }
  .new-visi p {
    font-size: 1.1rem;
  }

  .new-misi h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #b50b0b;
  }
  .new-misi p {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
  .new-misi li {
    font-size: 1.1rem;
    color: black;
    text-align: start;
    margin-bottom: 0.5rem;
  }
  .new-misi b {
    font-size: 1.3rem;
    color: #b50b0b;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-section-visi {
    height: 25rem;
    margin-top: 2.6rem;
  }
  .new-container-vismis {
    margin-inline: 1.5rem;
    /* flex-direction: column; */
    gap: 2rem;
  }
  .new-visi h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #b50b0b;
  }
  .new-visi p {
    font-size: 1.1rem;
  }

  .new-misi h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #b50b0b;
  }
  .new-misi p {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
  .new-misi li {
    font-size: 1.1rem;
    color: black;
    text-align: start;
    margin-bottom: 0.5rem;
  }
  .new-misi b {
    font-size: 1.3rem;
    color: #b50b0b;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .hero-section-visi {
    height: 20rem;
    margin-top: 2.6rem;
  }
  .new-container-vismis {
    margin-inline: 1.5rem;
    flex-direction: column;
    gap: 2rem;
  }
  .new-visi h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #b50b0b;
  }
  .new-visi p {
    font-size: 1rem;
  }

  .new-misi h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #b50b0b;
  }
  .new-misi p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  .new-misi li {
    font-size: 1rem;
    color: black;
    text-align: start;
    margin-bottom: 0.5rem;
  }
  .new-misi b {
    font-size: 1.1rem;
    color: #b50b0b;
  }
}

@media (min-width: 360px) and (max-width: 479px) {
  .hero-section-visi {
    height: 20rem;
    margin-top: 2.6rem;
  }
  .new-container-vismis {
    margin-inline: 1rem;
    flex-direction: column;
    gap: 2rem;
  }
  .new-visi h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #b50b0b;
  }
  .new-visi p {
    font-size: 1rem;
  }

  .new-misi h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #b50b0b;
  }
  .new-misi p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  .new-misi li {
    font-size: 1rem;
    color: black;
    text-align: start;
    margin-bottom: 0.5rem;
  }
  .new-misi b {
    font-size: 1.1rem;
    color: #b50b0b;
  }
}
