.hero-section-keuangan {
  background-image: url("../../assets/laporan-keuangan-hero.jpg");
  background-size: 100% 100%;
  background-position: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.header-kontak {
  width: 40%;
}

.cont-keuangan {
  margin-block: 3rem;
  margin-inline: 6rem;
  /* background-color: #b50b0b; */
  padding: 2rem;
  border-radius: 0.5rem;
}
.cont-keuangan h2 {
  font-size: 2.4rem;
  color: #b50b0b;
  font-weight: 600;
  margin-bottom: 1rem;
}
.cont-keuangan .accordion-button {
  font-size: 1.4rem;
  font-weight: 600;
}

.list-lap-keuangan {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.list-lap-keuangan p {
  font-size: 1.1rem;
}
.list-lap-keuangan h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #b50b0b;
}
.list-icon {
  color: #b50b0b;
}
.list-lap-keuangan button {
  font-size: 1rem;
  border: 1px solid #b50b0b;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: none;
  font-weight: 600;
  transition: all 0.4s;
}

.list-lap-keuangan button:hover {
  background-color: #b50b0b;
  color: white;
  scale: 1.1;
}
.pertumbuhan_keuangan {
  margin-block: 3rem;
  margin-inline: 6rem;
  padding: 2rem;
}
.pertumbuhan_keuangan img {
  max-width: 100vh;
}

.pertumbuhan_keuangan h2 {
  font-size: 2.4rem;
  color: #b50b0b;
  font-weight: 600;
  margin-bottom: 1rem;
}

.pertumbuhan_keuangan p {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  .cont-keuangan,
  .pertumbuhan_keuangan {
    margin-inline: 2rem;
  }

  .cont-keuangan ul {
    flex-direction: column;
  }

  .cont-keuangan li {
    flex-basis: 100%;
    margin-bottom: 1rem;
    padding: 2rem;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .hero-section-keuangan {
    height: 25rem;
  }
  .cont-keuangan,
  .pertumbuhan_keuangan {
    margin-block: 3rem;
    margin-inline: 0.5rem;
    /* background-color: #b50b0b; */
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
  .cont-keuangan,
  .pertumbuhan_keuangan h2 {
    font-size: 2rem;
    color: #b50b0b;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .list-lap-keuangan {
    flex-direction: column;
  }
  .pertumbuhan_keuangan img {
    width: 26rem;
  }
}
@media (min-width: 360px) and (max-width: 479px) {
  .hero-section-keuangan {
    height: 23rem;
  }
  .cont-keuangan,
  .pertumbuhan_keuangan {
    margin-block: 3rem;
    margin-inline: 0.5rem;
    /* background-color: #b50b0b; */
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
  .cont-keuangan,
  .pertumbuhan_keuangan h2 {
    font-size: 2rem;
    color: #b50b0b;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .list-lap-keuangan {
    flex-direction: column;
  }
  .pertumbuhan_keuangan img {
    width: 20rem;
  }
}
