/* Footer */
.container-client-slider {
  background-color: #ab2424;
  /* padding: 0.5rem 1rem 1rem 1rem; */
  height: 80vh;
  margin: 0;
  display: flex;
  align-items: center;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 120%;
}
.testimonial {
  width: 100%;
}

.testimonial .slick-track {
  width: 100%;
}

/* Header */
.header-client-slider {
  background-color: rgba(4, 4, 4, 0.4);
  width: 35%;
  margin-left: 7rem;
  padding: 3rem;
}

.header-client-slider h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: white;
  font-weight: 600;
}
.header-client-slider p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: white;
}
@media (max-width: 1400px) {
}
@media (max-width: 1300px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 992px) {
}
@media (max-width: 768px) {
}
@media (max-width: 480px) {
  .header-client-slider {
    width: 90vw;
    padding: 1rem;
    margin-left: 1.5rem;
  }
  .header-client-slider h1 {
    font-size: 2rem;
  }
}
@media (max-width: 360px) {
}
