.main-kalkulator {
  /* margin-inline: 20rem; */
  margin-block: 6rem;
}
.calkulator-pinjaman {
  /* margin-inline: 20rem; */
  margin-bottom: 3rem;
}
.calkulator-pinjaman-page {
  /* margin-inline: 20rem; */
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calkulator-pinjaman-page h2 {
  font-size: 2.4rem;
  text-align: center;
  color: #b50b0b;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.calkulator-pinjaman-page p {
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 1.5rem;
  margin-inline: 10%;
}
.cont-kalkulator {
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: flex-start;
  padding-inline: 20rem;
  margin-bottom: 2rem;
  /* flex-direction: column; */
}

.button-jenis-kredit {
  display: flex;
  gap: 1rem;
  /* background-color: aqua; */
}

.onClick {
  border: none;
  background-color: #b50b0b;
  padding: 1rem;
  color: #fff;
  transition: all 0.3s ease;
  font-size: 1rem;
  font-weight: 600;
}
.button-jenis-kredit .onClick:hover {
  background-color: #720b0b;
}

.nonClick {
  border: 2px solid #b50b0b;
  /* background-color: #b50b0b; */
  padding: 1rem;
  color: #b50b0b;
  transition: all 0.3s ease;
  font-size: 1rem;
  font-weight: 600;
}
.button-jenis-kredit .nonClick:hover {
  background-color: #720b0b;
  color: #fff;
  border: 2px solid #720b0b;
}

.form-kalkulator {
  flex-basis: 50%;
}
.nameKalk {
  font-size: 1.1rem;
}
.hasil-kalkulator {
  flex-basis: 50%;
}
.hasil-kalkulator h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

@media (max-width: 1400px) {
  .cont-kalkulator {
    padding-inline: 5rem;
  }
}
@media (max-width: 768px) {
  .cont-kalkulator {
    flex-direction: column;
    padding-inline: 1rem;
    align-items: center;
  }
  .button-jenis-kredit {
    display: flex;
    align-items: center;
    margin-inline: auto;
  }
  .calkulator-pinjaman-page {
    margin-inline: 1rem;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .cont-kalkulator {
    flex-direction: column;
    padding-inline: 1rem;
    align-items: center;
  }
  .button-jenis-kredit {
    display: flex;
    align-items: center;
    margin-inline: auto;
  }
  .calkulator-pinjaman-page {
    margin-inline: 1rem;
  }
  .calkulator-pinjaman-page h2 {
    font-size: 2rem;
  }
  .calkulator-pinjaman-page p {
    font-size: 1rem;
  }
  .button-jenis-kredit button {
    font-size: 1rem;
  }
  .nameKalk {
    font-size: 1rem;
  }
  .hasil-kalkulator tr {
    font-size: 1rem;
  }
}

@media (min-width: 360px) and (max-width: 479px) {
  .cont-kalkulator {
    flex-direction: column;
    padding-inline: 1rem;
    align-items: center;
  }
  .button-jenis-kredit {
    display: flex;
    align-items: center;
    margin-inline: auto;
  }
  .button-jenis-kredit button {
    font-size: 0.8rem;
  }
  .calkulator-pinjaman-page {
    margin-inline: 1rem;
  }
  .calkulator-pinjaman-page h2 {
    font-size: 2rem;
  }
  .calkulator-pinjaman-page p {
    font-size: 1rem;
  }
  .nameKalk {
    font-size: 1rem;
  }
  .hasil-kalkulator tr {
    font-size: 1rem;
  }
}
